exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-candidature-js": () => import("./../../../src/pages/candidature.js" /* webpackChunkName: "component---src-pages-candidature-js" */),
  "component---src-pages-formation-js": () => import("./../../../src/pages/formation.js" /* webpackChunkName: "component---src-pages-formation-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-international-js": () => import("./../../../src/pages/international.js" /* webpackChunkName: "component---src-pages-international-js" */),
  "component---src-pages-president-js": () => import("./../../../src/pages/president.js" /* webpackChunkName: "component---src-pages-president-js" */),
  "component---src-pages-recherche-js": () => import("./../../../src/pages/recherche.js" /* webpackChunkName: "component---src-pages-recherche-js" */),
  "component---src-templates-department-js": () => import("./../../../src/templates/department.js" /* webpackChunkName: "component---src-templates-department-js" */),
  "component---src-templates-doctoral-school-js": () => import("./../../../src/templates/doctoral-school.js" /* webpackChunkName: "component---src-templates-doctoral-school-js" */),
  "component---src-templates-faculty-js": () => import("./../../../src/templates/faculty.js" /* webpackChunkName: "component---src-templates-faculty-js" */)
}

